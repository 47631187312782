<template>
<div v-if="solutionsList.length > 0">
  <div class="swiper swiper-solutions h-screen">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide h-screen overflow-hidden"
        v-for="(solution, solutionIndex) in solutionsList"
        :key="solution.uid"
        :title="solution.title"
      >
        <div class="relative w-full h-full">
          <div
            v-if="solution.media.bg"
            class="absolute top-24 2xl:top-0 right-0 h-75"
          >
            <img
              :src="solution.media.bg[0]"
              alt=""
              class="block h-full max-w-none"
            />

            <template v-if="animateBg">
              <img
                v-for="(bg, bgIndex) in solution.media.bg"
                :key="bgIndex"
                :src="bg"
                alt=""
                class="absolute top-0 right-0 h-full max-w-none"
                data-swiper-animation="animate__fadeIn"
                data-duration="0.75s"
                :data-delay="`${0.2 + bgIndex * 0.2}s`"
              />
            </template>

            <transition name="fade-out">
              <div v-if="!animateBg && !hideBg">
                <img
                  v-for="(bg, bgIndex) in solution.media.bg"
                  :key="`bgIndex-${bgIndex}`"
                  :src="bg"
                  alt=""
                  class="absolute top-0 right-0 h-full max-w-none"
                />
              </div>
            </transition>

            <button-expand
              v-for="(product, productIndex) in solution.products"
              :key="product.uid"
              class="absolute z-10"
              :style="{
                top: `calc(100% * ${product.position.y})`,
                right: `calc(100% * ${product.position.x})`
              }"
              :reversed="product.position.reversed"
              data-swiper-animation="animate__zoomIn"
              data-duration="0.75s"
              :data-delay="`${1.4 + productIndex * 0.2}s`"
              data-swiper-out-animation="animate__zoomOut"
              :data-out-duration="`${0.4 + productIndex * 0.2}s`"
              @clicked="openProductDetails(product.uid)"
            >
              {{ product.title }}
            </button-expand>

            <info-panel
              :fact="solution.fact"
              :ref="`fact-${solutionIndex}`"
              class="absolute z-10"
              :style="{
                top: `calc(100% * ${solution.fact.position.y})`,
                right: `calc(100% * ${solution.fact.position.x})`
              }"
              data-swiper-animation="animate__zoomIn"
              data-duration="0.75s"
              data-delay="1.2s"
              data-swiper-out-animation="animate__zoomOut"
              data-out-duration="0.6s"
            />
          </div>

          <div
            v-show="!showBenefitsOverlay"
            class="container mx-auto px-8 h-full flex items-center"
            data-swiper-animation="animate__fadeIn"
            data-duration="1s"
            data-delay="1s"
            data-swiper-out-animation="animate__fadeOut"
            data-out-duration="1s"
          >
            <div class="relative z-50 w-1/3 pt-10 pb-2 lg:pb-6 pr-12">
              <div>
                <button
                  class="mb-4 w-9 h-9 inline-flex items-center justify-center
                        border-2 border-blue rounded-full
                        bg-blue text-sm font-black uppercase text-white
                        transition-colors icon-close
                      hover:bg-white hover:text-blue"
                  aria-label="Back"
                  @click="backToConcourse"
                />

                <h1
                  class="mb-4 text-2xl lg:text-3xl 2xl:text-5xl font-bold text-blue"
                >
                  {{ solution.title }}
                </h1>

                <h1
                  class="mb-4 text-lg 2xl:text-xl font-bold text-black"
                >
                  {{ solution.subtitle }}
                </h1>

                <div class="mb-7 max-h-30vh pr-4 overflow-auto custom-scrollbar">
                  <div
                    class="text-sm 2xl:text-lg"
                    v-html="solution.description"
                  />
                </div>

                <div class="mb-2">
                  <button
                    class="inline-block border-2 border-blue rounded-3xl
                            py-1 2xl:py-2 px-4 2xl:px-7
                          bg-white
                            text-sm 2xl:text-xl text-black font-bold
                            transition-colors
                            hover:bg-blue hover:text-white
                            focus:outline-none focus:shadow-xl"
                    @click="openBenefits"
                  >
                    Learn more
                    <span class="hidden xl:inline">
                      about the technology
                    </span>
                  </button>
                </div>

                <div>
                  <button
                    class="inline-block border-2 border-blue rounded-3xl
                            py-1 2xl:py-2 px-4 2xl:px-7
                            text-sm 2xl:text-xl font-bold
                            transition-colors
                            hover:bg-blue hover:text-white"
                    :class="isSelected(solution.uid) ? 'bg-blue text-white' : 'bg-white text-black'"
                    @click="requestDetails(solution.uid)"
                  >
                    {{ isSelected(solution.uid) ? 'Request confirmed': 'Request details' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer
      class="fixed z-40 left-0 bottom-0 w-full px-8 pt-9 pb-8"
    >
      <div class="flex items-center justify-between">
        <div class="min-w-30vw">
          <div class="swiper-nav-prev">
            <div
              v-if="prevSolution"
              :key="prevSolution.title"
              class="group inline-flex items-center text-base 2xl:text-lg
                    cursor-pointer transition-colors hover:text-blue select-none"
            >
              <i
                class="inline-flex items-center justify-center border-2 border-blue rounded-full
                      w-8 2xl:w-12 h-8 2xl:h-12 p-2
                      bg-blue
                      icon-arrow-left text-white mr-2
                      transition-colors
                      group-hover:bg-white group-hover:text-blue"
              />
              Back <span class="ml-1 text-blue font-bold">{{ prevSolution.title }}</span>
            </div>

            <button
              v-else
              class="group inline-flex items-center text-base 2xl:text-lg
                     cursor-pointer transition-colors hover:text-blue"
              @click="backToConcourse"
            >
              <i
                class="inline-flex items-center justify-center border-2 border-blue rounded-full
                       w-8 2xl:w-12 h-8 2xl:h-12 p-2 bg-blue
                       transition-colors
                       icon-arrow-left text-white mr-2
                     group-hover:bg-white group-hover:text-blue"
              />
              Back <span class="ml-1 font-bold text-blue">To Concourse</span>
            </button>
          </div>
        </div>

        <router-link
          to="/get-in-touch"
          class="mx-3 border-2 border-blue rounded-full py-2 lg:py-6 px-5 lg:px-12
          bg-blue text-sm 2xl:text-xl font-medium text-white font-bold
          transition-colors
          hover:bg-white hover:text-black
          focus:outline-none focus:bg-white focus:text-black"
        >
          Contact Us
        </router-link>

        <div class="min-w-30vw text-right">
          <div class="swiper-nav-next">
            <div
              v-if="nextSolution"
              :key="nextSolution.title"
              class="group inline-flex items-center text-base 2xl:text-lg
                    cursor-pointer transition-colors hover:text-blue select-none"
            >
              Next
              <span class="ml-1 text-blue font-bold">{{ nextSolution.title }}</span>
              <i
                class="inline-flex items-center justify-center border-2 border-blue rounded-full
                      w-8 2xl:w-12 h-8 2xl:h-12 p-2 bg-blue
                      icon-arrow-right text-white ml-2
                      transition-colors
                      group-hover:bg-white group-hover:text-blue"
              />
            </div>

            <button
              v-else
              class="group inline-flex items-center text-base 2xl:text-lg
                     cursor-pointer transition-colors hover:text-blue"
              @click="backToConcourse"
            >

              Out to the <span class="ml-1 font-bold text-blue">Concourse</span>
              <i
                class="inline-flex items-center justify-center border-2 border-blue rounded-full
                       w-8 2xl:w-12 h-8 2xl:h-12 p-2 bg-blue
                       transition-colors
                       icon-arrow-right text-white ml-2
                     group-hover:bg-white group-hover:text-blue"
              />
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>

  <transition name="fade-up-down">
    <div
      v-if="showBenefitsOverlay"
      class="fixed z-100 top-0 left-0 w-full bg-blue-950 text-white"
    >
      <router-link
        to="/"
        class="absolute left-8 top-8"
      >
        <img
          src="@/assets/images/logo-white.svg"
          alt="Global Pay"
          class="w-14 transition-opacity hover:opacity-80"
        />
      </router-link>

      <img
        v-if="solution.media.bg[3]"
        :src="solution.media.bg[3]"
        alt=""
        class="absolute top-0 right-0 h-full max-w-none"
      />

      <div class="container mx-auto px-8">
        <div class="relative z-10 h-screen py-16 lg:py-24 flex">
          <div
            class="w-1/4 lg:w-1/3 pt-4 lg:pt-12 pr-8
                  flex flex-col justify-center"
          >
            <div>
              <h1 class="mb-4 text-3xl xl:text-5xl font-light">
                Experience <span class="font-bold">the <br>
                best</span> in <span class="lowercase">{{ solution.title }}</span>
              </h1>

              <p
                v-if="solution.benefits.subtitle"
                class="mb-4"
              >
                {{ solution.benefits.subtitle }}
              </p>

              <div
                v-for="product in solution.products"
                :key="product.uid"
                class="mr-3 mb-2"
              >
                <a
                  class="inline-block border-2 border-blue rounded-3xl
                  py-1 2xl:py-2 px-4 2xl:px-7
                  bg-white shadow
                  text-sm 2xl:text-xl font-bold text-black
                  cursor-pointer
                  transition-colors
                  hover:border-white hover:bg-blue hover:text-white
                  focus:outline-none focus:shadow-xl"
                  @click="openProductDetails(product.uid)"
                >
                  {{ product.title }}
                </a>
              </div>
            </div>
          </div>

          <div class="w-3/4 lg:w-2/3 pr-12 xl:pr-48 flex flex-col justify-center">
            <div class="-m-3 flex flex-wrap">
              <div
                v-for="benefit in solution.benefits.items"
                :key="benefit.id"
                class="p-3 w-1/3"
              >
                <benefit-card :benefit="benefit" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="fixed z-40 left-0 bottom-0 w-full px-8 py-8">
        <div class="flex items-center justify-between">
          <div class="min-w-30vw">
            <button
              class="mr-3 inline-flex items-center text-base 2xl:text-lg text-white
                    cursor-pointer transition-colors hover:text-green"
              @click="hideBenefits"
            >
              <i
                class="inline-flex items-center justify-center border-2 border-white rounded-full
                      w-8 2xl:w-12 h-8 2xl:h-12 p-2
                      icon-arrow-left text-white mr-2"
              />
              Back to <span class="ml-1 font-bold">{{ solution.title }} overview</span>
            </button>
          </div>

          <router-link
            to="/get-in-touch"
            class="mx-3 border-2 border-white rounded-full py-2 lg:py-6 px-5 lg:px-12
                   text-sm 2xl:text-xl font-medium text-white font-bold
                   transition-colors
                 hover:bg-white hover:text-blue
                   focus:outline-none focus:bg-white focus:text-blue"
          >
            Contact Us
          </router-link>

          <div class="min-w-30vw"></div>
        </div>
      </footer>
    </div>
  </transition>

  <transition name="fade-up-down">
    <product
      v-if="showProductOverlay"
      :product="selectedProduct"
      @hide="hideProductDetails"
      @hide-all="hideAll"
    />
  </transition>
</div>
</template>

<script>
import {
  ButtonExpand,
  InfoPanel,
  BenefitCard,
  Product,
} from '@/components';
import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store';
import Swiper, { Navigation } from 'swiper';
import SwiperAnimation from '@cycjimmy/swiper-animation';
import functionToPromise from '@cycjimmy/awesome-js-funcs/esm/typeConversion/functionToPromise';

// TODO: remove after getting real coordinates
const productCoordinates = [
  {
    uid: '3h9qd0s8',
    title: 'Xenial Display',
    position: {
      x: 0.3193,
      y: 0.5135,
      reversed: true,
    },
  },
  {
    uid: 'pq1b6cnb',
    title: 'XC23 Terminal',
    position: {
      x: 0.5000,
      y: 0.5735,
      reversed: false,
    },
  },
  {
    uid: 'hexabgex',
    title: 'P400',
    position: {
      x: 0.4570,
      y: 0.5800,
      reversed: false,
    },
  },
  {
    uid: '1ashrj19',
    title: 'e285',
    position: {
      x: 0.3283,
      y: 0.5435,
      reversed: true,
    },
  },
  {
    uid: 'bq2cv2fp',
    title: 'Samsung Galaxy Tab Active Pro',
    position: {
      x: 0.1050,
      y: 0.5735,
      reversed: true,
    },
  },
  {
    uid: 'wfcopqg5',
    title: 'Samsung QMR Series 50”',
    position: {
      x: 0.1110,
      y: 0.1935,
      reversed: true,
    },
  },
  {
    uid: 'z5emmj7i',
    title: 'MP10',
    position: {
      x: 0.0400,
      y: 0.4835,
      reversed: true,
    },
  },
  {
    uid: 'mnhrbw5y',
    title: 'X22',
    position: {
      x: 0.4000,
      y: 0.3235,
      reversed: true,
    },
  },
];

let swiperAnimation;

const outAnimate = (activeElements) => {
  const runOutTasks = activeElements.map((el) => {
    if (el.animationData.isRecovery) {
      return Promise.resolve();
    }

    if (!el.animationData.outEffect) {
      return Promise.resolve();
    }

    return functionToPromise(() => {
      /* eslint-disable no-param-reassign */
      el.style.cssText = el.animationData.styleCache;
      el.style.visibility = 'visible';
      el.style.cssText += `animation-duration:${el.animationData.outDuration}; -webkit-animation-duration:${el.animationData.outDuration};`;

      el.classList.add(el.animationData.outEffect, 'animated');
      /* eslint-enable no-param-reassign */
    }, 500);
  });

  return Promise.all(runOutTasks);
};

export default {
  name: 'SolutionPage',
  components: {
    ButtonExpand,
    InfoPanel,
    BenefitCard,
    Product,
  },
  data() {
    return {
      benefits: [],
      showBenefitsOverlay: false,
      showProductOverlay: false,
      selectedProduct: null,
      solutionsList: [],
      swiper: null,
      animateBg: true,
      hideBg: false,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.solutions,
      getters.content.products,
      getters.contactForm.selected,
    ]),
    solutionId() {
      return this.$route.params.solutionId;
    },
    slideIndex() {
      return this.solutionsList.find((s) => s.uid === this.solutionId).order || 0;
    },
    prevSolution() {
      const currentSolutionIndex = this.solutions.findIndex((s) => s.uid === this.solutionId);
      return this.solutions[currentSolutionIndex - 1];
    },
    nextSolution() {
      const currentSolutionIndex = this.solutions.findIndex((s) => s.uid === this.solutionId);
      return this.solutions[currentSolutionIndex + 1];
    },
    solution() {
      return this.solutionsList.find((s) => s.uid === this.solutionId);
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateBg = false;
    }, 1200);

    const self = this;

    this.$nextTick(() => {
      swiperAnimation = new SwiperAnimation();

      this.swiper = new Swiper('.swiper-solutions', {
        modules: [Navigation],
        initialSlide: self.slideIndex,
        speed: 1000,
        navigation: {
          nextEl: '.swiper-nav-next',
          prevEl: '.swiper-nav-prev',
        },
        on: {
          init(swiper) {
            swiperAnimation.init(swiper).animate();
          },
          slideChange(swiper) {
            self.$router.push({
              name: 'solutions.index',
              params: {
                solutionId: self.getSolutionIdByOrder(swiper.activeIndex),
              },
            }).catch(() => {});

            swiperAnimation.init(swiper).animate();
          },
        },
      });
    });

    this.getSolutions();
  },
  methods: {
    ...mapActions([
      actions.contactForm.select,
    ]),
    getProductsList(solution) {
      const relatedIds = solution.related || [];

      return relatedIds.map((id) => {
        const product = this.products.find((p) => p.uid === id);
        return {
          ...product,
          position: productCoordinates.find((coord) => coord.uid === product.uid).position,
        };
      });
    },
    getSolutions() {
      if (!this.solutionId) return;
      const data = this.solutions;
      this.solutionsList = data.map((s, index) => ({
        ...s,
        order: index,
        products: this.getProductsList(s),
      }));
    },
    async backToConcourse() {
      const swiperAnimInit = swiperAnimation.init(this.swiper);
      await outAnimate(swiperAnimInit.animations.activeElements);

      this.hideBg = true;

      setTimeout(() => {
        this.$router.push({
          name: 'concourse-view.index',
          params: {
            hideOverlay: true,
            solutionId: this.solutionId,
          },
        });
      }, 500);
    },
    openBenefits() {
      this.showBenefitsOverlay = true;
    },
    hideBenefits() {
      this.showBenefitsOverlay = false;
    },
    openProductDetails(productId) {
      this.selectedProduct = this.products.find((p) => p.uid === productId);
      this.showProductOverlay = true;
      this.showBenefitsOverlay = true;
    },
    hideProductDetails() {
      this.showProductOverlay = false;
    },
    hideAll() {
      this.showBenefitsOverlay = false;
      this.showProductOverlay = false;
    },
    isSelected(solutionId) {
      return !!this.selected.find((selectedId) => selectedId === solutionId);
    },
    requestDetails(solutionId) {
      this[actions.contactForm.select](solutionId);
    },
    getSolutionIdByOrder(order) {
      return this.solutionsList[order].uid;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_mixins';

.h-75 {
  height: 75%;

  @include respond-above(xxl) {
    height: 100%;
  }
}
</style>
